import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Illustration wrap=flex",
        "componentName": "Illustration",
        "wrap": "flex"
      }}>{`<Illustration
  as={Login}
  width="full"
  accessibilityLabel="Hand holding a phone, displaying a password form."
  maxWidth="500px"
/>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Illustration" mdxType="Props" />
    <h2 {...{
      "id": "as",
      "style": {
        "position": "relative"
      }
    }}>{`As`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`The illustration component to render, choose one of:
`}<a parentName="p" {...{
        "href": "/components/illustration/illustrations"
      }}>{`Chameleon illustration search`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Illustration wrap=flex",
        "componentName": "Illustration",
        "wrap": "flex"
      }}>{`<Illustration as={Login} accessibilityLabel="Hand holding a phone, displaying a password form." width="full" />
<Illustration as={Mail} accessibilityLabel="Hand holding a phone, displaying an envelope."  width="full" />
<Illustration as={PageNotFound} accessibilityLabel="Man looking for the page 404." width="full" />
`}</code></pre>
    <h2 {...{
      "id": "accessibilitylabel",
      "style": {
        "position": "relative"
      }
    }}>{`AccessibilityLabel`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To meet accessibility standars, you should provide an accessibilityLabel.
Describe what's displayed on the illustration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Illustration wrap=flex",
        "componentName": "Illustration",
        "wrap": "flex"
      }}>{`<Illustration as={Login} accessibilityLabel="Hand holding a phone, displaying a password form." width="full" />
<Illustration as={Mail} accessibilityLabel="Hand holding a phone, displaying an envelope."  width="full" />
<Illustration as={PageNotFound} accessibilityLabel="Man looking for the page 404." width="full" />
`}</code></pre>
    <h2 {...{
      "id": "height",
      "style": {
        "position": "relative"
      }
    }}>{`Height`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Change the illustration's height.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Illustration as={Login} accessibilityLabel="Hand holding a phone, displaying a password form." height={5} />
<Illustration as={Mail} accessibilityLabel="Hand holding a phone, displaying an envelope."  height="full" />
<Illustration as={SearchResults} accessibilityLabel="Man searching on the computer with magnifying glass."  height="auto" />
<Illustration as={PageNotFound} accessibilityLabel="Man looking for the page 404." height="300px" />
`}</code></pre>
    <h2 {...{
      "id": "maxheight",
      "style": {
        "position": "relative"
      }
    }}>{`MaxHeight`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Change the illustration's max-height.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Illustration as={Login} accessibilityLabel="Hand holding a phone, displaying a password form." maxHeight={5} />
<Illustration as={Mail} accessibilityLabel="Hand holding a phone, displaying an envelope."  maxHeight="full" />
<Illustration as={SearchResults} accessibilityLabel="Man searching on the computer with magnifying glass."  maxHeight="auto" />
<Illustration as={PageNotFound} accessibilityLabel="Man looking for the page 404." maxHeight="300px" />
`}</code></pre>
    <h2 {...{
      "id": "maxwidth",
      "style": {
        "position": "relative"
      }
    }}>{`MaxWidth`}</h2>
    <p><inlineCode parentName="p">{`default: full`}</inlineCode></p>
    <p>{`Change the illustration's max-width.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Illustration as={Login} accessibilityLabel="Hand holding a phone, displaying a password form." maxWidth={5} />
<Illustration as={Mail} accessibilityLabel="Hand holding a phone, displaying an envelope."  maxWidth="full" />
<Illustration as={SearchResults} accessibilityLabel="Man searching on the computer with magnifying glass."  maxWidth="auto" />
<Illustration as={PageNotFound} accessibilityLabel="Man looking for the page 404." maxWidth="300px" />
`}</code></pre>
    <h2 {...{
      "id": "width",
      "style": {
        "position": "relative"
      }
    }}>{`Width`}</h2>
    <p><inlineCode parentName="p">{`default: auto`}</inlineCode></p>
    <p>{`Change the illustration's width.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Illustration as={Login} accessibilityLabel="Hand holding a phone, displaying a password form." width={5} />
<Illustration as={Mail} accessibilityLabel="Hand holding a phone, displaying an envelope."  width="full" />
<Illustration as={SearchResults} accessibilityLabel="Man searching on the computer with magnifying glass."  width="auto" />
<Illustration as={PageNotFound} accessibilityLabel="Man looking for the page 404." width="300px" />
`}</code></pre>
    <h2 {...{
      "id": "looking-for-illustrations",
      "style": {
        "position": "relative"
      }
    }}>{`Looking for illustrations?`}</h2>
    <ul>
      <li parentName="ul">{`Designers can browse and discover illustrations in our
`}<a parentName="li" {...{
          "href": "https://www.figma.com/file/GVKW6mmJ56VNzIxK1FSze6/4.-Foundations---Illustrations?node-id=1%3A95"
        }}>{`Figma Design Toolkit`}</a>{`.`}</li>
      <li parentName="ul">{`Developers can find illustrations in our
`}<a parentName="li" {...{
          "href": "/components/illustration/illustrations"
        }}>{`Chameleon illustration search`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      